import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/react-hooks";

import AlertModal from "components/AlertModal/AlertModal";
import { httpClient } from "settings/apolloClient";
import { InstrumentsPanelWrapper, InfoParagraph } from "components/StyledComponents";
import { API } from "constants/shipmentType";

import InstrumentsPanelContent from "./InstrumentsPanelContent";
import LOADING_CONCRETE_SHIPMENT from "./graphql/queries/loadingConcreteShipment.js";
import PLANT_LOCATIONS_QUERY from "./graphql/queries/plantLocations";
import ControlMenu from "./ControlMenu/ControlMenu";
import ManualOperation from "./ManualOperation/ManualOperation";

import { CURRENT_AUTOMATION_STATUS_QUERY } from "../AutomationStatus/automationStatusQueries";
import ACTUATORS_QUERY from "./graphql/queries/actuatorsQuery";

import { Sensors } from "./Sensors/Sensors";
import SENSORS_QUERY from "./graphql/queries/sensorsQuery";

import CURRENT_USER_QUERY from "./graphql/queries/currentUserQuery";

import { CopyButton } from "../../components/CopyButton/CopyButton";

import "./InstrumentsPanel.scss";

const PAGE_CLASSES = [".main-panel", ".navbar", ".map-content"];

/**
 * Defines the poll interval for loadingShipmentQuery when
 * there isn't any loading shipment yet.
 */
const POLL_INTERVAL = 1000;

const InstrumentsPanel = () => {
  const [
    shown,
    setShown,
  ] = useState(false);

  useEffect(() => {
    document.body.classList.toggle("sidebar-mini");
    document.body.classList.add("instruments-panel");

    PAGE_CLASSES.forEach(el => document.querySelector(el).classList.add("instruments-panel"));

    return () => {
      document.body.classList.toggle("sidebar-mini");
      document.body.classList.add("instruments-panel");

      PAGE_CLASSES.forEach(el => document.querySelector(el).classList.remove("instruments-panel"));
    };
  }, []);

  const [isControlMenuOpen, setIsControlMenuOpen] = useState(false);
  const handleControlMenuOpen = (open) => {
    setIsControlMenuOpen(open)
  }

  const {
    data: loadingConcreteShipmentData,
    loading,
    refetch,
    stopPolling,
    startPolling,
  } = useQuery(LOADING_CONCRETE_SHIPMENT, {
    fetchPolicy: "network-only",
    pollInterval: POLL_INTERVAL,
    client: httpClient,
  });

  const { data: automationStatus } = useQuery(CURRENT_AUTOMATION_STATUS_QUERY, {
    fetchPolicy: "network-only",
    pollInterval: POLL_INTERVAL,
    client: httpClient,
  });

  const { data: sensorsData } = useQuery(SENSORS_QUERY, {
    fetchPolicy: "network-only",
    client: httpClient,
  });

  const { sensors } = sensorsData || [];

  const { actuators } = [];

  const { data: getCurrentUserData } = useQuery(CURRENT_USER_QUERY, {
    fetchPolicy: "network-only",
    client: httpClient,
  });

  const { getCurrentUser } = getCurrentUserData || {};

  const {
    loadingConcreteShipment,
    manualOperations,
  } = loadingConcreteShipmentData || {};

  const {
    data: devicesData,
  } = useQuery(PLANT_LOCATIONS_QUERY, {
    client: httpClient,
    variables: {
      concreteShipmentId: loadingConcreteShipment ? loadingConcreteShipment.id : 0,
    },
    fetchPolicy: "network-only",
    pollInterval: POLL_INTERVAL * 3,
  });

  useEffect(() => {
    if (loadingConcreteShipment) {
      stopPolling();

      if (loadingConcreteShipment.shipmentType === API && !shown) {
        setShown(true);

        AlertModal.fire({
          title: "Carregamento iniciado remotamente",
          type: "info",
          html: (
            <InfoParagraph>
              Não se esqueça de marcar quando o caminhão estiver pronto
            </InfoParagraph>
          ),
          showCancelButton: false,
          confirmButtonColor: "#1D84C6",
          confirmButtonText: "Continuar",
          allowOutsideClick: true,
          allowEscapeKey: true,
          allowEnterKey: true,
        });
      }
    } else if (!loading) {
      setShown(false);
      startPolling(POLL_INTERVAL);
    }
  }, [
    loadingConcreteShipment,
    loading,
    setShown,
    shown,
    startPolling,
    stopPolling,
  ]);

  const { devices } = devicesData || {};

  const [device] = devices || [];
  const {
    id,
    quantity,
    concreteFormula,
    controllerFinishedAt,
  } = loadingConcreteShipment || {};

  const {
    code,
    fck,
    concreteFamily,
  } = concreteFormula || {};

  const {
    nominal,
    deviation,
  } = (concreteFamily && concreteFamily.slump) || {};

  const onFinish = () => {
    window.location.reload();
  };

  return (
    <React.Fragment>
      <ManualOperation
        concreteShipmentId={id}
        controllerHasFinished={!!controllerFinishedAt}
        refetchConcreteShipmentQuery={refetch}
        manualOperations={manualOperations}
      />

      {
        concreteFormula && (
          <div className="formula-info">
            <div className="code-container">
              <span>{`Código: ${loadingConcreteShipment.code}`}</span>
              <div className="copy-button">
                <CopyButton text={loadingConcreteShipment.code} />
              </div>
            </div>
            <span>{`Traço: ${code}`}</span>
            <span>{`FCK: ${fck} | Slump: ${nominal} ± ${deviation}`}</span>
            <span>{`Volume: ${quantity}`}</span>
          </div>
        )
      }

      {
        (getCurrentUser && (getCurrentUser.role !== "API_USER")) && 
        <ControlMenu
          show={!loadingConcreteShipment}
          onRefetch={refetch}
          handleControlMenuOpen={handleControlMenuOpen}
        />
      }

      {(sensors && Boolean(sensors.length)) && <Sensors sensorsList={sensors} controlMenuOpen={isControlMenuOpen} />}

      <div className="map-content">
        <InstrumentsPanelWrapper>
          <img
            className="plant-background-image"
            src={device && `${window.location.origin}${device.plantImagePath}`}
            alt="Painel de instrumentos"
            style={{
              position: "relative",
              top: 0,
              left: 0,
              width: "100%",
              height: "auto",
            }}
          />
          <InstrumentsPanelContent
            refetchConcreteShipmentQuery={refetch}
            onFinish={onFinish}
            loadingShipment={loadingConcreteShipment}
            plantLocations={device ? device.plantLocations : []}
            automationStatus={automationStatus}
          />
        </InstrumentsPanelWrapper>
      </div>
    </React.Fragment>
  );
};

export default InstrumentsPanel;
