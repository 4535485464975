import React, { useState } from "react";
import styled from "styled-components";
import {
  Card, Row, Col, Container
} from "reactstrap";

import ProcessesTable from "./ProcessesTable";
import ConfigsTable from "./ConfigsTable";
import EstimatorsTable from "./EstimatorsTable";
import EstimateTable from "./EstimateTable";


const CardContainer = styled(Card)`
  width: 100%;
`;

function ControllerSettings() {
  const [processCode, setProcessCode] = useState("");
  const [processName, setProcessName] = useState("");
  const [selectedEstimator, setSelectedEstimator] = useState(null);

  return (
    <div className="content">
      <Container fluid>
        <Row>
          <Col sm={12} md={12}>
            <CardContainer>
              <ProcessesTable
                setProcessCode={setProcessCode}
                setProcessName={setProcessName}
              />
            </CardContainer>
          </Col>
          <Col sm={12} md={12}>
            { processCode && (
              <Card style={{ marginTop: "35px" }}>
                <ConfigsTable
                  processName={processName}
                  processCode={processCode}
                />
              </Card>
            )}
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6}>
            <CardContainer>
              <EstimatorsTable
                selectedEstimator={selectedEstimator}
                setSelectedEstimator={setSelectedEstimator}
              />
            </CardContainer>
          </Col>
          <Col sm={12} md={6}>
            { selectedEstimator && (
              <CardContainer>
                <EstimateTable
                  selectedEstimator={selectedEstimator}
                />
              </CardContainer>
            )}
            
          </Col>
        </Row>
      </Container>
    </div>
  )
};

export default ControllerSettings;
