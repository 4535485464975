import axios from 'axios';
import qs from 'qs';


const HOST_API = '';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }), ...config});

  return res.data;
};

// ----------------------------------------------------------------------

export const post = async (url, data) => {
  const res = await axiosInstance.post(url, data);
  return res.data;
};

// ----------------------------------------------------------------------

export const put = async (url, data) => {
  const res = await axiosInstance.put(url, data);
  return res.data;
}

// ----------------------------------------------------------------------

export const endpoints = {
  processes: {
    list: '/automation/api/processes_active',
    enable: (process_code) => `/automation/api/process/enable/${process_code}`,
    disable: (process_code) => `/automation/api/process/disable/${process_code}`,
  },
  configs: {
    list: (process_code) => `/automation/api/configs_by_process_code/${process_code}`,
    update: (config_id) => `/automation/api/configs/${config_id}`,
  },
  estimators: {
    list: '/automation/api/estimators_grouped_by_code',
    details: (code) => `/automation/api/estimator/${code}`,
    create: '/automation/api/estimators',
  }
};

